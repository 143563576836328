import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Layout, Row, Col, Tabs, Form, Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import authorization from "../../../auth/authorization";
import { lookUp } from "../../../api/LookUpAPI";
import { getCases } from "../../../api/CasesAPI";
import { useTablePreferences } from "../../../hooks/useTablePreferences";
import BulkUpdateModal from '../../../components/BulkUpdate/BulkUpdateModal'
import UserRolesAndRightsHOComponent from "../../../components/UserRolesAndRightsHOComponent/UserRolesAndRightsHOComponent";
import { CaseFilterMap, CaseQueryParamMap } from "../../../constants/CaseFilter"
import { createTableQueryParamMap, createTableSortByMap } from "../../../utils/createCaseFilterQueryParamMap";
import { dateTimeFormatter } from "../../../utils/dateFormatter";
import CasePatientEditDetails from "./CasePatientEditDetails";
import ConsolidatedCaseDetails from "./ConsolidatedCaseDetails";
import CaseFilter from "./CaseFilter";
import { StyledTabWrapper, StyledComponentWrapper, StyledTabs, StyledTable, Title, SearchFilter } from "./StyledComponents";
import SelectBulkAction from "./SelectBulkAction";
import ScheduleConsultationModal from "./ScheduleConsultationModal";
import { useColumns } from "./hooks/useColumns";

const { Content } = Layout;
const { TabPane } = Tabs;

const TABLE_NAME = "manage-cases";

const getFetchedDataHandler = (setter) => (res) => {
  if (!res.data?.payload) {
    throw Error();
  }
  const fetchedData = res.data.payload.map((item) => ({ ...item, key: item._id }));
  setter(fetchedData);
};

const TableDataMap = {
  CASE_ID: CaseFilterMap.CASE_ID,
  TELEMEDICINE_CATEGORY_ID: CaseFilterMap.TELEMEDICINE_CATEGORY_ID,
  PHYSICIAN_ID: CaseFilterMap.PHYSICIAN_ID,
  PATIENT_NAME: `${CaseFilterMap.PATIENT_FIRST_NAME} ${CaseFilterMap.PATIENT_LAST_NAME}`,
  PATIENT_EMAIL: 'patientEmail',
  PATIENT_ID: 'patientId',
  STATE_ID: CaseFilterMap.STATE_ID,
  WHEEL_CONSULT_ID: 'wheelConsultId',
  CREATED_DATE: CaseFilterMap.CREATED_DATE,
  UPDATED_DATE: CaseFilterMap.UPDATED_DATE,
  CONSULTATION_TYPE: CaseFilterMap.TYPE,
  STATUS: CaseFilterMap.STATUS,
  ASSIGNED_TIME: 'assignedTime',
  ASSIGNED_GROUP: 'assignedGroup',
  ASSIGNED_GROUP_TIME: 'assignedGroupTime',
}

const TableSortByMap = createTableSortByMap(TableDataMap);

const defaultPreferences = { currentPage: 1, tablePageSize: 100, sortOrder: 'descend' }

const ManageCases = ({ userType }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [cases, setCases] = useState([]);
  const [caseId, setCaseId] = useState("");
  const [editViewPatientDetails, setEditViewPatientDetails] = useState(false);
  const [clients, setClients] = useState([]);
  const [states, setStates] = useState([]);
  const [telemedicineCategory, setTelemedicineCategory] = useState([]);
  const [caseStatus, setCaseStatus] = useState([]);
  const [totalCase, setTotalCase] = useState(0);
  const [filterParams, setFilterParams] = useState({});
  const [viewCaseDetails, setCaseDetailsModal] = useState(false);
  const [scheduleModalIsVisible, setScheduleModalIsVisible] = useState(false); //TODO: do we use it?
  const [bulkAction, setBulkAction] = useState(null)
  const [selectedCases, setSelectedCases] = useState([])
  const [filterForm] = Form.useForm();

  const { getTablePreferences, updateTablePreferences } = useTablePreferences();
  const { currentPage, tablePageSize, sortOrder, sortBy } = getTablePreferences(TABLE_NAME, defaultPreferences);

  const checkIsAllowedAuthUser = useCallback((action) => {
    return authorization.isAllowed(userType, action);
  }, [userType]);

  const onPatientEditIconClick = useCallback((caseId) => {
    setCaseId(caseId);
    setEditViewPatientDetails(true);
  }, []);

  const onCaseDetailsViewClick = useCallback((caseId) => {
    setCaseId(caseId);
    history.push(`/cases/${caseId}`);
  }, [history]);

  const columns = useColumns({ sortOrder, sortBy, TableDataMap, userType, onPatientEditIconClick, onCaseDetailsViewClick });

  useEffect(() => {
    const fetchDataMap = {
      "case-status": setCaseStatus,
      "state": setStates,
      "client": setClients,
      "tele-medicine-category": setTelemedicineCategory,
    }

    Object.entries(fetchDataMap).forEach(([key, setter]) => {
      lookUp(key).then(getFetchedDataHandler(setter)).catch(() => message.error(`Unable to fetch ${key}`));
    });
  }, []);

  const getCasesByParams = useCallback((paramMap) => {
    setLoading(true);
    getCases(paramMap)
      .then((res) => {
        const payload = res.data?.payload;
        if (!payload.data) {
          throw Error();
        }
        setTotalCase(payload.count);
        const caseData = payload.data.map((_case) => ({
          ..._case,
          key: _case.caseId,
        }));
        setCases(caseData);
        message.success("Successfully loaded Case List");
      })
      .catch((error) => {
        console.log("Error fetching cases", error);
        message.error("Unable to load Case List. Please try again!");
      })
      .finally(() => setLoading(false));
  }, []);

  const fetchCases = useCallback(() => {
    const newTablePreferences = {
      currentPage,
      tablePageSize,
      sortOrder,
      sortBy: TableSortByMap[sortBy] || sortBy,
    };
    const tableFilterMap = createTableQueryParamMap(newTablePreferences);

    const hasFilterValues = Object.values(filterParams).some((value) => {
      return Array.isArray(value) ? value.length > 0 : !!value
    });

    hasFilterValues
      ? getCasesByParams({ ...tableFilterMap, ...filterParams })
      : setCases([]);
  }, [currentPage, tablePageSize, sortOrder, sortBy, filterParams, getCasesByParams]);

  const onTableRowClick = (caseId) => {
    setCaseId(caseId);
    setCaseDetailsModal(true);
  };

  const handleSearchValueChange = (value) => {
    setFilterParams(params => ({ ...params, [CaseQueryParamMap.SEARCH_TERM]: value }));
    updateTablePreferences(TABLE_NAME, { currentPage: 1 })
  };

  const onFilter = useCallback((value) => {
    setFilterParams(value);
    updateTablePreferences(TABLE_NAME, { currentPage: 1 });
  }, [updateTablePreferences]);

  const clearFilter = useCallback(() => {
    filterForm.resetFields();
    setFilterParams({});
    updateTablePreferences(TABLE_NAME, { currentPage: 1 });
    setSelectedCases([]);
    setCases([]);
  }, [filterForm, updateTablePreferences]);

  const onChangePage = (page) => {
    updateTablePreferences(TABLE_NAME, { currentPage: page })
    setSelectedCases([])
  };

  const onPageSizeChange = (_, size) => {
    updateTablePreferences(TABLE_NAME, { currentPage: 1, tablePageSize: size })
    setSelectedCases([])
  };

  const onChangeOrder = (_pagination, _filters, sorter) => {
    updateTablePreferences(TABLE_NAME, {
      sortOrder: sorter.order || 'ascend',
      sortBy: sorter.field,
    });
    setSelectedCases([])
  }

  const casesForTable = cases.map((c) => {
    return {
      key: c.caseId,
      rowKey: c.caseId,
      [TableDataMap.CASE_ID]: { caseId: c.caseId, colourCode: c.colourCode },
      [TableDataMap.TELEMEDICINE_CATEGORY_ID]: c.categoryName,
      [TableDataMap.PHYSICIAN_ID]: c.physicianName,
      [TableDataMap.PATIENT_NAME]: c.patientName,
      [TableDataMap.PATIENT_EMAIL]: c.patientEmail,
      [TableDataMap.PATIENT_ID]: c.patientId,
      [TableDataMap.STATE_ID]: c.state,
      [TableDataMap.WHEEL_CONSULT_ID]: c.wheel_consultId,
      [TableDataMap.CREATED_DATE]: dateTimeFormatter(c.createdDate),
      [TableDataMap.UPDATED_DATE]: dateTimeFormatter(c.updatedDate),
      [TableDataMap.CONSULTATION_TYPE]: c.consultationType,
      [TableDataMap.STATUS]: c.status,
      [TableDataMap.ASSIGNED_TIME]: c.assignedTime ? dateTimeFormatter(c.assignedTime) : "NA",
      [TableDataMap.ASSIGNED_GROUP]: c.assignedGroupName || "NA",
      [TableDataMap.ASSIGNED_GROUP_TIME]: c.groupAssignedTime ? dateTimeFormatter(c.groupAssignedTime) : "NA",
    }
  })

  return (
    <>
      <Content>
        <StyledTabWrapper>
          <StyledTabs type="card">
            <TabPane tab="All" key="1">
              <StyledComponentWrapper>
                <Spin
                  spinning={loading}
                  size="large"
                  indicator={<LoadingOutlined />}
                >
                  <Row>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <Title level={2}>All Cases</Title>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                      <SearchFilter
                        placeholder="Search Cases"
                        onSearch={handleSearchValueChange}
                        size="large"
                        allowClear
                      />
                    </Col>
                  </Row>
                  <CaseFilter
                    clients={clients}
                    states={states}
                    telemedicineCategory={telemedicineCategory}
                    caseStatus={caseStatus}
                    onFilter={onFilter}
                    clearFilter={clearFilter}
                    onSearchCases={fetchCases}
                    form={filterForm}
                  />
                  <Row>
                    <SelectBulkAction
                      disabled={!selectedCases?.length}
                      onChange={setBulkAction}
                      checkIsAllowedAuthUser={checkIsAllowedAuthUser}
                      isArchived={Boolean(filterForm.getFieldValue("archived"))}
                    />
                  </Row>
                  <Row>
                    <Col span={24}>
                      <StyledTable
                        columns={columns}
                        dataSource={casesForTable}
                        onChange={onChangeOrder}
                        rowSelection={{
                          selectedRowKeys: selectedCases.map(_case => _case[TableDataMap.CASE_ID]),
                          onChange: (_, selectedRows) => setSelectedCases(selectedRows.map(_case => {
                            return { ..._case, [TableDataMap.CASE_ID]: _case[TableDataMap.CASE_ID].caseId };
                          })),
                        }}
                        onRow={(record) => ({
                          onClick: () => onTableRowClick(record[TableDataMap.CASE_ID].caseId),
                        })}
                        pagination={{
                          total: totalCase,
                          showTotal: (totalCase) => `Total ${totalCase} Cases`,
                          current: currentPage,
                          pageSize: tablePageSize,
                          pageSizeOptions: ["5", "10", "20", "50", "100", "500"],
                          showSizeChanger: true,
                          onChange: onChangePage,
                          onShowSizeChange: onPageSizeChange,
                        }}
                        scroll={{ x: 720 }}
                      />
                    </Col>
                  </Row>
                </Spin>
              </StyledComponentWrapper>
            </TabPane>
          </StyledTabs>
        </StyledTabWrapper>
        {caseId && viewCaseDetails && (
          <ConsolidatedCaseDetails
            caseId={caseId}
            viewModal={viewCaseDetails}
            closeModal={setCaseDetailsModal}
            setBulkAction={setBulkAction}
            setSelectedCases={setSelectedCases}
          />
        )}

        {caseId && editViewPatientDetails && (
          <CasePatientEditDetails
            caseId={caseId}
            viewPatientEditModal={editViewPatientDetails}
            closePatientEditModal={setEditViewPatientDetails}
          />
        )}
        <ScheduleConsultationModal
          visible={scheduleModalIsVisible}
          setVisible={setScheduleModalIsVisible}
          caseId={caseId}
          fetchCases={fetchCases}
        />
        {bulkAction && (
          <BulkUpdateModal
            selectedCases={selectedCases}
            setSelectedCases={setSelectedCases}
            bulkAction={bulkAction}
            setBulkAction={setBulkAction}
            fetchCases={fetchCases}
          />
        )}
      </Content>
    </>
  );
};

export default UserRolesAndRightsHOComponent(ManageCases);
